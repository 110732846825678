<template>
    <div  class="creator_search_list_wrap">
        <!--   심사위원 리스트 레이아웃-->
        <ul class="list_judge_wrap">
            <li class="list_item" v-for="(data,index) in memberData" :key="`judge${index}`" @click="judgeConfirm(data)">
                <div class="thumbnail" :style="` background-image: url(${returnThumbnail(data)});`" >
                    <div class="curator" v-if="data.g_idx === 2">Curator</div>
                    <!--          백그라운드 이미지 :src 들어가야함-->
                    <!--          <img class="user_background" src="@/assets/image/main/hot_curator.jpg" alt=""/>-->
                </div>
                <div class="user_detail">
                    <div class="user_wrap">
                        <img class="profile" :src="returnProfile(data)" alt="">
                        <div class="badge" v-if="data.mb_premium === 1"></div>
                        <div class="user_nickname">{{ data.mb_nick }}</div>
                    </div>
                    <div class="sub_wrap">
                        <img class="icon heart" src="@/assets/image/main/heart.png" alt="">
                        <div class="like_cnt">{{ numFormat(data.likeCnt) }}</div>
                        <span class="sub_point">·</span>
                        <div class="item_subject">Item <span>{{ numFormat(data.itemCnt) }}</span></div>
                    </div>
                </div>
            </li>
        </ul>
        <!--    <button @click="moveDetail(`${idx}`)">유저 프로필</button>-->
    </div>
</template>

<script>
import alertMixins from "@/mixins/alertMixins";
import imageOption from "@/mixins/imageOption";
import {mapState} from "vuex";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";

export default {
    name: "MemberListJudgeLayout",
    mixins: [alertMixins, imageOption],
    components: {
    },
    inject: [],
    provide() {
        return{

        }
    },
    props: {
        memberData: {default: () => {}},
    },
    data() {
        return{
            // memberData: {},
        }
    },
    beforeRouterEnter() {},
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods:{
        judgeConfirm(data){
            let dataArr = data;
            if (data.mb_nick === this.UserInfo.mb_nick ) {
                this.errorAlert(`${this.$t('do_not_myself')}`);
                return false;
            }
            this.createConfirm({
                content : `${this.$t('judgeConfirm', {name: dataArr.mb_nick})}`,
                cancelText : this.$t('cancel'),
                confirmText : this.$t('yes'),
                confirm: () => {
                    let judgeData =
                        {profile: dataArr.Files.Profile, mb_nick: dataArr.mb_nick, mb_no: dataArr.mb_no};

                    EventBus.$emit('judgeSelect', judgeData);
                    this.modalClose();
                },
                cancel: () => {

                }
            });
        },
        isEmptyData(data) {
            return util.isEmpty(data);
        },
        numFormat(num) {
            if(util.isEmpty(num) || isNaN(num)) {
                return 0;
            }
            return util.Number.numFormat(num);
        },
        replaceBr(content) {
            if (util.isEmpty(content)) {
                return '';
            }
            return content.replace(/(\n|\r\n)/g, '<br>');
        },
        returnThumbnail(data) {
            if(util.isEmpty(data.Files) || util.isEmpty(data.Files.ProfileBack)) {
                return "";
            }
            return `${data.Files.ProfileBack[0].org_url}?${this.setImageOptions(220, 140, 'jpg')}`;
        },
        returnProfile(data) {
            if(util.isEmpty(data.Files) || util.isEmpty(data.Files.Profile)) {
                return "";
            }
            return `${data.Files.Profile[0].org_url}?${this.setImageOptions(66, 66, 'png')}`;
        },
        modalClose() {
            this.$modal.hide('judge-search-modal');
        },
    },
    watch: {

    },
}
</script>

<style scoped>

</style>
